import * as types from './mutation-types';

export default {
  [types.SET_PAYMENT_LINK_LIST](state, payload) {
    state.payment_link_list = payload;
  },
  [types.SET_PAYMENT_LINK](state, payload) {
    state.payment_link = payload;
  }
};
