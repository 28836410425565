import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
  payment_link_list: [],
  new_payment_link: {},
  payment_link: {}
};

export const payment_link = {
  name: 'payment_link',
  state,
  actions,
  getters,
  mutations
};

export * from './mutation-types';

export default payment_link;
