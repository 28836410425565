import Vue from 'vue';
import Toast from 'vue-toastification';
import * as LottiePlayer from '@lottiefiles/lottie-player';
import VueClipboard from 'vue-clipboard2';
import VueCountdown from '@chenfengyuan/vue-countdown';
import VueMoment from 'vue-moment';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment-timezone';
import vWow from 'v-wow';
import VueSocialSharing from 'vue-social-sharing';
import 'moment/locale/pt-br'; // Moment js Load Locales ('en' comes loaded by default)
// Import the CSS or use your own!
import 'sweetalert2/dist/sweetalert2.min.css';
import 'vue-toastification/dist/index.css';
import VueMeta from 'vue-meta';

Vue.use(VueMeta, {
  title: 'SnailBank'
});
Vue.use(LottiePlayer);
Vue.use(vWow);
Vue.use(Toast);
Vue.component(VueCountdown.name, VueCountdown);
Vue.use(VueSweetalert2);

Vue.use(VueSocialSharing);

moment.locale('pt-br');
moment.tz.setDefault('America/Sao_Paulo');
Vue.use(VueMoment, { moment });

Vue.use(VueReCaptcha, { siteKey: '6LfWSJ4aAAAAAOZk3OB2k8iFBnqsMPQlY1kGUMsv' });

VueClipboard.config.autoSetContainer = true; // add this line
Vue.use(VueClipboard);

import money from 'v-money';
Vue.use(money, { precision: 4 });

import PortalVue from 'portal-vue';
Vue.use(PortalVue);
