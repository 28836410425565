import * as types from './mutation-types';

import ApiService from '@/core/services/api.service';

export default {
  [types.GET_PAYMENT_LINK_LIST]({ commit }, params) {
    return new Promise((resolve, reject) => {
      ApiService.query('/tools/payment_link', params)
        .then(({ data, status }) => {
          if (status === 200) {
            commit(types.SET_PAYMENT_LINK_LIST, data);
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.STORE_PAYMENT_LINK](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/tools/payment_link', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.CANCEL_PAYMENT_LINK](context, payment_link_id) {
    return new Promise((resolve, reject) => {
      ApiService.delete('/tools/payment_link/' + payment_link_id)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.GET_PAYMENT_LINK]({ commit }, payment_link_id) {
    return new Promise((resolve, reject) => {
      ApiService.get('/payment_link/pay/' + payment_link_id)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
            commit(types.SET_PAYMENT_LINK, data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.PAY_PAYMENT_LINK_SNAILCOIN](context, payment_link_id) {
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post('/tools/payment_link/pay/snail', { payment_link_id })
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  [types.SEND_WHATSAPP_PAYMENT_LINK_SNAILCOIN](payload) {
    return new Promise((resolve, reject) => {
      ApiService.post('/tools/payment_link/send/whatsapp', payload)
        .then(({ data, status }) => {
          if (status === 200) {
            resolve(data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};
