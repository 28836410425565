//ACTION TYPES
export const GET_PAYMENT_LINK_LIST = 'GET_PAYMENT_LINK_LIST';
export const STORE_PAYMENT_LINK = 'STORE_PAYMENT_LINK';
export const CANCEL_PAYMENT_LINK = 'CANCEL_PAYMENT_LINK';
export const GET_PAYMENT_LINK = 'GET_PAYMENT_LINK';
export const PAY_PAYMENT_LINK_SNAILCOIN = 'PAY_PAYMENT_LINK_SNAILCOIN';
export const SEND_WHATSAPP_PAYMENT_LINK_SNAILCOIN = 'SEND_WHATSAPP_PAYMENT_LINK_SNAILCOIN';

//MUTATION TYPES
export const SET_PAYMENT_LINK_LIST = 'SET_PAYMENT_LINK_LIST';
export const SET_NEW_PAYMENT_LINK = 'SET_NEW_PAYMENT_LINK';
export const SET_PAYMENT_LINK = 'SET_PAYMENT_LINK';
