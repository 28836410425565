import Vue from 'vue';
import Vue2Filters from 'vue2-filters';
import { VueMaskFilter } from 'v-mask';
import VueTheMask from 'vue-the-mask';

//VueTheMask
Vue.use(VueTheMask);

//VueMask
Vue.filter('VMask', VueMaskFilter);

Vue.filter('currencyReal', function (value, symbol) {
  let currency = Vue.filter('currency');
  symbol = symbol != null ? symbol : 'R$ ';
  return currency(value, symbol, 2, { thousandsSeparator: '.', decimalSeparator: ',' });
});

Vue.filter('currencySnail', function (value, symbol) {
  let currency = Vue.filter('currency');
  symbol = symbol != null ? symbol : 'SN$ ';
  return currency(value, symbol, 2, { thousandsSeparator: '.', decimalSeparator: ',' });
});

Vue.use(Vue2Filters);
